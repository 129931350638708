@import '../Styling/Styling.css';

// Navbar
.navbar {
    background-color: #171717;

    .navbar-nav {
        margin: 1rem 0;

        .nav-link {
            color: #ffff;
            margin: 0 2rem;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            text-transform: uppercase;

            transition: all 0.5s ease-in-out;

            &:hover {
                color: darken(#ffff, 50%);
            }

            &:active {
                color: darken(#ffff, 50%);
            }
        }
    }
}

.navbar-toggler {
    color: #40e0fd;
    background-color: #f1f1f1;
}

@media screen and (max-width: 990px) {
    .navbar-nav {
        .nav-link {
            text-align: right;
        }
    }
}
