@import '../Styling/Styling.css';
.navbar {
  background-color: #171717;
}
.navbar .navbar-nav {
  margin: 1rem 0;
}
.navbar .navbar-nav .nav-link {
  color: white;
  margin: 0 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}
.navbar .navbar-nav .nav-link:hover {
  color: gray;
}
.navbar .navbar-nav .nav-link:active {
  color: gray;
}

.navbar-toggler {
  color: #40e0fd;
  background-color: #f1f1f1;
}

@media screen and (max-width: 990px) {
  .navbar-nav .nav-link {
    text-align: right;
  }
}/*# sourceMappingURL=Menu.css.map */