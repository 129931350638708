@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Roboto:wght@400;500;700&family=Source+Code+Pro:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #171717;
}

.color-black {
  color: #0f0f0f;
}

.color-grey {
  color: #bcb4b4;
}

.color-white {
  color: #f1f1f1;
}

.color-blue {
  color: #40e0fd;
}

.blackBG {
  background-color: #161616 !important;
}

.menuBG {
  background-color: #161616 !important;
}

.bold {
  font-weight: 700;
}

.title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.overlay {
  position: relative;
  margin-top: 1rem;
}

.subtitle {
  font-family: "Source Code Pro", monospace;
  color: #40e0fd;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.text-xl {
  font-size: 3rem;
}

.text-lg {
  font-size: large;
}

.text-md {
  font-size: medium;
}

.text-sm {
  font-size: smaller;
}

.padding-home {
  padding-top: 20vh;
  padding-bottom: 15vh;
}

.my-6 {
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.mt-6 {
  margin-top: 15vh;
}

.mb-6 {
  margin-bottom: 15vh;
}

.py-6 {
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 50px;
  color: #f1f1f1;
  cursor: pointer;
  transition: all 1s ease;
}
.back-to-top:hover {
  color: #bebebe;
  transform: scale(1.2);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: transparent;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 1s ease;
}
a:hover {
  color: #cccccc;
}

.social {
  display: flex;
  position: fixed;
  justify-content: center;
  border-radius: 5px;
  transform: rotate(90deg);
  top: 40%;
  left: -20px;
  z-index: 5;
}

.social h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  padding: 20px;
  transform: rotate(180deg);
}

.social-icons {
  padding: 20px;
  transform: rotate(-90deg);
  transition: all 1s ease;
}
.social-icons:hover {
  transform: scale(1.4) rotate(-90deg);
}

.skill {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  padding: 0.75rem 1rem;
  background-color: #bcb4b4;
  color: #171717;
  border-radius: 0.25rem;
  overflow-y: hidden;
  transition: all 0.8s ease-in-out;
  cursor: default;
}
.skill:hover {
  background-color: #40e0fd;
}

.portfolio .card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: all 1.5s ease-in-out;
}
.portfolio .card:hover {
  transform: scale(1.05);
  background-color: #40e0fd;
}
.portfolio .card .card-img:hover {
  opacity: 0.8;
}

.elk-male {
  position: relative;
  left: -200px;
}

.elk-female {
  position: relative;
  right: -200px;
}

.skill-list-front {
  position: relative;
  right: -200px;
}

.skill-list-back {
  position: relative;
  right: -200px;
}

.card-1 {
  position: relative;
  left: -200px;
}

.card-3 {
  position: relative;
  left: -200px;
}

.card-2 {
  position: relative;
  right: -200px;
}

.card-4 {
  position: relative;
  right: -200px;
}

#proPic {
  position: relative;
  bottom: -150px;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 3s forwards;
  animation-delay: 0.5s;
}
@keyframes slide {
  100% {
    bottom: 0;
  }
}
@media screen and (max-width: 1367px) and (max-height: 769px) {
  .social {
    left: -7%;
  }
}
@media screen and (max-width: 1025px) and (max-height: 641px) {
  .padding-home {
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
  .navbar {
    padding: 0;
  }
  .social {
    display: none;
  }
  .back-to-top {
    display: none;
  }
}
@media screen and (max-width: 912px) {
  .elk {
    display: none;
  }
  .social {
    display: none;
  }
  .card {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 420px) {
  .py-6 {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}/*# sourceMappingURL=Styling.css.map */